
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'New team',
    },
    modalButtonText: {
      type: String,
      default: 'Create',
    },
    prefillValue: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const teamNameValue = ref(props.prefillValue)
    const isDefaultTeam = ref(false)

    return {
      teamNameValue,
      isDefaultTeam,
    }
  },
})
